import { IconProp as FaIconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Tooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import { CSSProperties, MouseEvent, ReactNode, useState } from "react";
import { PlacementSide } from "@/components/Tooltip/Tooltip";
import {
    dangerHex,
    darkHex,
    lightHex,
    mediumHex,
    primaryHex,
    secondaryHex,
    successHex,
    warningHex,
} from "@/constants/colors";
import { computed } from "@/util/functions";
import "@/styles/shared/faTooltip.scss";

export type IconColor = "primary" | "secondary" | "success" | "danger" | "warning" | "dark" | "light" | "medium";

type SharedIconWithTooltipProps = {
    onClick?: (event: MouseEvent) => void;
    tooltipText?: string;
    color?: IconColor;
    iconStyle?: CSSProperties;
    iconSize?: SizeProp;
    iconClassName?: string;
    onlyTitle?: boolean;
    placement?: PlacementSide;
    disableMobileClick?: boolean;
    children?: ReactNode;
    tooltipClassName?: string;
};

type IconProp = SharedIconWithTooltipProps & {
    icon: FaIconProp;
    imageIconPath?: never;
};

type ImageProp = SharedIconWithTooltipProps & {
    icon?: never;
    imageIconPath: string;
    alt?: string;
};

type IconWithTooltipProps = IconProp | ImageProp;

function getColorCode(color?: IconColor) {
    switch (color) {
        case "primary":
            return primaryHex;
        case "secondary":
            return secondaryHex;
        case "success":
            return successHex;
        case "warning":
            return warningHex;
        case "danger":
            return dangerHex;
        case "dark":
            return darkHex;
        case "light":
            return lightHex;
        case "medium":
            return mediumHex;
    }
    return undefined;
}

export function IconWithTooltip(props: IconWithTooltipProps) {
    const [open, setOpen] = useState(false);

    const iconComponent = computed(() => {
        if (props.icon) {
            return (
                <FontAwesomeIcon
                    icon={props.icon}
                    style={{ ...props.iconStyle, color: getColorCode(props.color) }}
                    size={props.iconSize}
                    title={props.onlyTitle ? props.tooltipText : undefined}
                    onClick={props.onClick}
                />
            );
        } else if (props.imageIconPath) {
            return (
                <img
                    src={props.imageIconPath}
                    style={{ ...props.iconStyle, color: getColorCode(props.color) }}
                    title={props.onlyTitle ? props.tooltipText : undefined}
                    onClick={props.onClick}
                    alt={props.alt}
                />
            );
        }
        return null;
    });

    const trigger = (
        <span
            className={props.iconClassName}
            onClick={event => {
                event.stopPropagation();
                setOpen(true);
            }}>
            {iconComponent}
        </span>
    );

    const content = props.tooltipText || props.children;
    if (!content) {
        return trigger;
    }

    return (
        <Tooltip.TooltipProvider delayDuration={300}>
            <Tooltip.Root open={open} onOpenChange={setOpen}>
                <Tooltip.Trigger asChild>{trigger}</Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content className={clsx(["fa-tooltip", props.tooltipClassName])} collisionPadding={8}>
                        {content}
                        <Tooltip.Arrow className="fa-tooltip__arrow" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        </Tooltip.TooltipProvider>
    );
}
